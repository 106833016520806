import {
    MDBCard,
    MDBCardFooter,
    MDBCardHeader,
    MDBCol,
    MDBRow,
} from "mdb-react-ui-kit";
import { FC, useState } from "react";
import Image from "next/image";
import { ReviewReadableTO } from "@/models/common/common";
import styles from "../../../styles/home/Home.CardReview.module.scss";
import contactsSvg from "../../../utils/svg-icon/contacts";

const CardReview: FC<{
    content: ReviewReadableTO;
}> = ({ content }) => {
    const { Quote } = contactsSvg;
    const [avatarError, setAvatarError] = useState(false);

    return (
        <MDBCard
            alignment="center"
            className={`${styles.container} mx-2 mx-lg-4`}
        >
            <MDBCardHeader className="text-start fw-bold">
                <MDBRow className="d-flex justify-content-end px-2">
                    <MDBCol size={2} className={styles.quotes}>
                        <Quote />
                    </MDBCol>
                </MDBRow>
                <MDBRow className={`${styles.textComment} p-3 pt-1 pb-0`}>
                    <MDBCol size={12}>{content.body}</MDBCol>
                </MDBRow>
            </MDBCardHeader>
            <MDBCardFooter>
                <MDBRow className="p-3 ps-0">
                    <MDBCol
                        size={6}
                        lg={6}
                        xl={5}
                        center
                        className={`d-flex justify-content-center ${styles.carousel}`}
                    >
                        <div className={styles.image_outer_container}>
                            <div className={styles.image_inner_container}>
                                <div className="position-relative h-100">
                                    <Image
                                        className="object-cover h-100"
                                        src={
                                            avatarError
                                                ? "/images/tours/across-italy-reduced.jpg"
                                                : content.image
                                        }
                                        alt="avatar-image"
                                        fill
                                        onError={() => {
                                            setAvatarError(true);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </MDBCol>
                    <MDBCol>
                        <MDBRow className={`${styles.textName} text-start`}>
                            {content.user}
                        </MDBRow>
                        <MDBRow className={`${styles.textWork} text-start`}>
                            {content.title}
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </MDBCardFooter>
        </MDBCard>
    );
};

export default CardReview;
